<template>
  <div class="www flex-h-center">
    <div id="wx_reg" style="display: inline-block"></div>
  </div>
</template>


<script>
import * as ww from '@wecom/jssdk';
export default {
  name: 'Login',
  data() {
    return {};
  },
  mounted() {
    this.login();
  },
  methods: {
    login() {
      if (localStorage.getItem('UserId')) {
        this.$router.push('/operateManage/business/agency');
      } else {
        const URL = 'https://test.dcops.fre.tencent-cloud.com';
        // const URL = process.env.NODE_ENV === 'development' ? 'https://test.dcops.fre.tencent-cloud.com' : 'https://dcops.fre.tencent-cloud.com';
        const { el } = ww.createWWLoginPanel({
          el: '#wx_reg',
          params: {
            appid: 'appid',
            agentid: 'agentid1',
            // agentid: process.env.NODE_ENV === 'development' ? 'agentid1' : 'agentid2',
            redirect_uri: URL + (location.search || ''),
            state: 'loginState',
            redirect_type: 'callback',
          },
          onCheckWeComLogin: ({ isWeComLogin }) => {
            console.log(isWeComLogin);
          },
          onLoginSuccess: ({ code }) => {
            const { from } = this.$route.query;
            console.log(this.$router.resolve({ path: '/translate/business/translate', query: { code, from } }));
            this.$router.replace({ path: '/translate/business/translate', query: { code, from } });
          },
          onLoginFail(err) {
            console.log(err);
          },
        });
      }
    },
  },
};
</script>

<style>
.www {
  /* margin: auto; */
  text-align: center;
  margin-top: 10%;
}
</style>
